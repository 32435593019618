<script>
import APIStore from '@app/services/API/Store';

export default {
  props: {
    providers: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      provider: {
        provider: this.form.provider,
        ...Object.keys(this.form.fields)
          .reduce((prev, curr) => ({ [curr]: '', ...prev }), {}),
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  created() {
    const providerData = this.providers.find((v) => v.provider === this.form.provider);
    if (providerData) {
      Object.keys(this.form.fields)
        .forEach((k) => (this.provider[k] = providerData[k]));
    }
  },
  methods: {
    handle() {
      this.isLoading = true;
      APIStore.updateEmailMarketingProviders(this.authStore.uuid, [{ ...this.provider }])
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        {{ form.title }} <AppFeatureBadge class="ml-3" position="is-right" />
      </h2>
      <div v-html="form.text" />
    </div>

    <div class="column">
      <component
        :is="form.isPro ? 'GoProWrapper' : 'div'"
        class="box is-relative"
        :has-sep="false">
        <form novalidate @submit.prevent="dataIsValid(isLoading) && handle()">
          <div class="columns is-multiline">
            <div v-for="(field, k) in form.fields" :key="k" class="column" :class="field.column">
              <BaseField v-model="provider[k]" :field="field" />
            </div>
          </div>

          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </component>
    </div>
  </div>
</template>
