<script>
import APIStore from '@app/services/API/Store';
import StoreAutomationsForm from '@app/components/StoreAutomationsForm.vue';

export default {
  components: {
    StoreAutomationsForm,
  },
  head: {
    title: 'Connexions externes - Outils Marketing',
  },
  forms: [
    {
      isPro: true,
      provider: 'SENDINBLUE',
      title: 'Brevo (ex Sendinblue)',
      text: `
        <p>
          Où trouver votre clé API ? Dans la section «&nbsp;<strong>SMTP et API</strong>&nbsp;»
          de votre compte Brevo (anciennement Sendinblue).
        </p>
        <p>
          Vous pouvez également suivre notre tutoriel sur le sujet en
          <a
            class="has-text-weight-bold"
            href="https://youtu.be/RATVXt4_Dsk"
            target="_blank"
            rel="noopener noreferrer">
            cliquant ici
          </a>
        </p>
      `,
      fields: {
        api_key: {
          label: 'Clé API V3',
          type: 'text',
          column: 'is-12',
          inputAttrs: {
            placeholder: 'XXXXXXXXXX',
          },
        },
      },
    },
    {
      isPro: true,
      provider: 'ACTIVE_CAMPAIGN',
      title: 'ActiveCampaign',
      text: `
        <p>
          Où trouver vos accès d'API ? Dans la section «&nbsp;<strong>Paramètres</strong>&nbsp;», puis
          «&nbsp;<strong>Développeur</strong>&nbsp;» de votre compte ActiveCampaign.
        </p>
        <p>
          Vous pouvez également suivre notre tutoriel sur le sujet en
          <a
            class="has-text-weight-bold"
            href="https://youtu.be/RATVXt4_Dsk"
            target="_blank"
            rel="noopener noreferrer">
            cliquant ici
          </a>
        </p>
      `,
      fields: {
        api_key: {
          label: 'Clé API',
          type: 'text',
          column: 'is-12',
          inputAttrs: {
            placeholder: 'XXXXXXXXXX',
          },
        },
        api_url: {
          label: 'URL API',
          type: 'text',
          column: 'is-12',
          inputAttrs: {
            placeholder: 'https://xxxxxxxxx.com',
          },
        },
      },
    },
    {
      isPro: true,
      provider: 'GET_RESPONSE',
      title: 'GetResponse',
      text: `
        <p>
          Où trouver votre clé API ? Dans la section «&nbsp;<strong>Intégrations & API</strong>&nbsp;»,
          puis «&nbsp;<strong>API</strong>&nbsp;» de votre compte GetResponse.
        </p>
        <p>
          Point d'attention : <strong>les clés API GetResponse expirent au bout de 90 jours</strong>,
          charge à vous de faire le renouvellement avant expiration.
        </p>
        <p>
          Vous pouvez également suivre notre tutoriel sur le sujet en
          <a
            class="has-text-weight-bold"
            href="https://youtu.be/RATVXt4_Dsk"
            target="_blank"
            rel="noopener noreferrer">
            cliquant ici
          </a>
        </p>
      `,
      fields: {
        api_key: {
          label: 'Clé API',
          type: 'text',
          column: 'is-12',
          inputAttrs: {
            placeholder: 'XXXXXXXXXX',
          },
        },
      },
    },
  ],
  data: () => ({ providers: null }),
  mounted() {
    const loader = this.$buefy.loading.open();
    APIStore.getEmailMarketingProviders(this.$store.getters['auth/store'].uuid)
      .then(({ data }) => (this.providers = data))
      .finally(() => loader.close());
  },
};
</script>

<template>
  <div>
    <div class="box is-primary content">
      <p>
        Pour alimenter vos <strong>listes</strong> et gérer vos <strong>autorépondeurs</strong>,
        connectez Teachizy à vos outils Marketing : <strong>Brevo</strong> (anciennement Sendinblue),
        <strong>ActiveCampaign</strong> et/ou <strong>GetResponse</strong>.
      </p>
      <p>
        Vous devrez ensuite associer chacune de vos formations Teachizy
        avec une liste préalablement créée dans votre outil Marketing.
        Pour cela, rendez vous dans l'onglet « <strong>Paramètres</strong> » de vos formations.
      </p>
    </div>
    <template v-if="providers">
      <StoreAutomationsForm
        v-for="(form, k) in $options.forms"
        :key="k"
        :providers="providers"
        :form="form"
      />
    </template>
  </div>
</template>
